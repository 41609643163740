/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import { Route, Switch } from "react-router-dom";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { CssBaseline, useMediaQuery } from "@material-ui/core";
import Loader from "@icarius-common/loader";
import { AudioPlayer } from "@icarius-common/audioPlayer";
import { GridProcessResultDialog } from "@icarius-common/gridProcessResultDialog";
import { Poll } from "@icarius-common/poll";
import { createTheme } from "@icarius-utils/theme";
import { LG_DEVICE_WIDTH_BREAKPOINT } from "@icarius-utils/constants";
import { Home } from "@icarius-pages/home";
import useTheme from "./hooks/useTheme";
import useWrapper from "./hooks/useWrapper";
import Menu from "./menu";
import Header from "./header";
import Footer from "./footer";
import Dialogs from "./dialogs";

const Wrapper = () => {

  const {
    isLoading,
    color,
    availableMenus,
    allRoutes,
    customRoutes,
    canChangeSociety,
    dialogsState,
    drawerIsOpen,
    setDrawerIsOpen,
    handleOpenItem,
    connectedUsersEnabled,
  } = useWrapper();

  const { theme, handleToggleTheme } = useTheme();
  const matchesMoreOrEqualThanLG = useMediaQuery(`(min-width:${LG_DEVICE_WIDTH_BREAKPOINT}px)`);

  return (
    <MuiThemeProvider theme={createTheme(theme?.theme, color)}>
      <div id="menu-container">
        <Loader open={isLoading} />
        {
          Boolean(availableMenus?.length > 0) &&
          <>
            <Header
              withDrawerToggle={!matchesMoreOrEqualThanLG}
              routes={allRoutes}
              dialogsState={dialogsState}
              handleRegisterAssistance={() => dialogsState.setAssistanceDialogIsOpen(true)}
              handleToggleDrawer={() => setDrawerIsOpen((prev) => !prev)}
              handleToggleTheme={handleToggleTheme}
              handleOpenItem={handleOpenItem}
              connectedUsersEnabled={connectedUsersEnabled}
            />
            <div id="drawer-container" className={matchesMoreOrEqualThanLG ? "autoHide" : ""} style={{ position: "relative" }}>
              <CssBaseline />
              <Menu
                open={drawerIsOpen}
                variant={matchesMoreOrEqualThanLG ? "permanent" : "temporary"}
                customRoutes={customRoutes}
                availableMenus={availableMenus}
                showChangeSocietyMenuItem={canChangeSociety}
                handleOpenItem={handleOpenItem}
                handleClose={() => setDrawerIsOpen(false)}
              />
              <main className="page" style={{ marginTop: matchesMoreOrEqualThanLG && "65px" }}>
                <Switch>
                  {
                    allRoutes.map(route =>
                      <Route
                        key={route.path}
                        path={route.path}
                        component={route.component}
                        exact
                      />
                    )
                  }
                  <Route component={Home} />
                </Switch>
              </main>
              <Dialogs dialogsState={dialogsState} />
            </div>
            <Footer onSocietyClick={() => canChangeSociety && dialogsState.setChangeSocietyDialogIsOpen(true)} />
            <GridProcessResultDialog />
            <Poll />
            <AudioPlayer />
          </>
        }
        <a id="downloadAnchorElem" style={{ display: "none" }} />
      </div>
    </MuiThemeProvider>
  )
}

export default Wrapper;