import React from "react";
import { useSelector } from "react-redux";
import { getLocalizedString } from "@icarius-localization/strings";
import { WorldIcon } from "@icarius-icons";
import { getAppColor, getAssociatedCountries } from "src/app/selectors";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";

const TimezoneButton = ({ handleOpenTimezoneDialog }) => {
  const associatedCountries = useSelector(getAssociatedCountries);
  const color = useSelector(getAppColor);

  if (!associatedCountries || associatedCountries?.length === 0) return null;

  return (
    <CustomIconButton
      title={getLocalizedString("timeZones")}
      onClick={handleOpenTimezoneDialog}
      noBorder
    >
      <WorldIcon htmlColor={color} height="23px" />
    </CustomIconButton>
  );
}

export default TimezoneButton;
