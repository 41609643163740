import React from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import MenuItem from "./menuItem";
import { List } from "@material-ui/core";
import { useSelector } from "react-redux";
import { getAlertsAmount, getUserRole, getDigitalDocumentsAmount } from "src/app/selectors";
import DEFAULT_LIST_ITEMS from "./defaultListItem";

const DrawerDefaultItems = (props) => {

  const {
    handleOpenItem,
    availableMenus,
    showChangeSocietyMenuItem
  } = props;

  const digitalDocumentsQuantity = useSelector(getDigitalDocumentsAmount);
  const alertsAmount = useSelector(getAlertsAmount);
  const role = useSelector(getUserRole);

  const getDefaultItemsToShow = () => {
    return DEFAULT_LIST_ITEMS.filter(item => {
      return (
        ((item.title === getLocalizedString("changeSociety") && showChangeSocietyMenuItem) || item.title !== getLocalizedString("changeSociety")) &&
        (!item.roles || item.roles.includes(role)) && availableMenus?.includes(item.path.replace("/", ""))
      )
    });
  }

  const getDefaultItemsAmount = (item) => {
    if (item.title === getLocalizedString("alerts")) return alertsAmount;
    if (item.title === getLocalizedString("digitalDocuments")) return digitalDocumentsQuantity;
    return null;
  }

  return (
    <List key={"default-menu"}>    
      {
        getDefaultItemsToShow().map((item, index) =>
          <MenuItem
            key={index}
            icon={item.icon}
            label={item.title}
            amount={getDefaultItemsAmount(item)}
            onClick={() => handleOpenItem(item)}
          />
        )
      }
    </List>
  );
}

export default DrawerDefaultItems;
