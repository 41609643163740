import React from "react";
import { Drawer, Divider } from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import paths from "@icarius-localization/paths";
import { AboutUsIcon } from "@icarius-icons";
import DrawerUser from "./user";
import DrawerLogo from "./logo";
import MenuItem from "./menuItem";
import DrawerCustomItems from "./customItems";
import DrawerDefaultItems from "./defaultItems";

const Menu = (props) => {

    const {
        open,
        variant,
        customRoutes,
        availableMenus,
        showChangeSocietyMenuItem,
        handleOpenItem,
        handleClose,
    } = props;

    const hasCustomItemsToShow = () => {
        if (customRoutes?.length > 0) return true;
        if (availableMenus?.includes(paths.settings.replace("/", ""))) return true;
    }

    const handleOpenItemAndCloseDrawer = (item) => {
        handleOpenItem(item);
        handleClose();
    }

    return (
        <Drawer
            id={"drawer"}
            variant={variant}
            open={open}
            onClose={handleClose}
        >
            <div id={"drawer-content"} className={variant === "permanent" ? "autoHide" : ""}>
                <DrawerUser />
                <Divider className={"drawer-divider"} />
                <DrawerLogo handleCloseDrawer={handleClose} />
                <Divider className={"drawer-divider"} />
                <DrawerDefaultItems
                    availableMenus={availableMenus}
                    showChangeSocietyMenuItem={showChangeSocietyMenuItem}
                    handleOpenItem={handleOpenItemAndCloseDrawer}
                />
                {
                    hasCustomItemsToShow() &&
                    <>
                        <Divider className={"drawer-divider"} />
                        <DrawerCustomItems
                            data={customRoutes}
                            handleOpenItem={handleOpenItemAndCloseDrawer}
                        />
                    </>
                }
                <Divider className={"drawer-divider"} />
                <MenuItem
                    onClick={() => handleOpenItem({ path: paths.aboutUs })}
                    icon={AboutUsIcon()}
                    label={getLocalizedString("aboutUs")}
                />
            </div>
        </Drawer>
    );
}

export default Menu;