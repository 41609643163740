import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { roles } from "@icarius-utils/constants";
import ChangePinDialog from "@icarius-common/changePinDialog";
import MyCertificatesAndReceiptsDialog from "@icarius-common/myCertificatesAndReceiptsDialog/components/index";
import MyDigitalDocumentsDialog from "@icarius-common/myDigitalDocumentsDialog/components/index";
import RequestDocumentsDialog from "@icarius-common/requestDocumentsDialog";
import MyVacations from "@icarius-common/myVacationsDialog";
import RegisterAssistanceDialog from "@icarius-common/registerAssistanceDialog";
import SocietySelectDialog from "@icarius-pages/login/components/societySelectDialog";
import { getAssistanceData, getUserRole } from "src/app/selectors";
import { getHasToChangePin, getSocietySelected, getSocieties } from "@icarius-pages/login/selectors";
import { selectSociety } from "@icarius-pages/login/actions";
import ModalAbout from "./modalAbout";
import TimezoneDialog from "./timezoneDialog/index";

const DialogContainer = ({ dialogsState }) => {

  const dispatch = useDispatch();

  const history = useHistory();
  const assistanceData = useSelector(getAssistanceData);
  const hasToChangePin = useSelector(getHasToChangePin);
  const societySelected = useSelector(getSocietySelected);
  const societies = useSelector(getSocieties);
  const role = useSelector(getUserRole);

  const registerAssistanceIsOpen = assistanceData?.can_register_assistance && dialogsState.assistanceDialogIsOpen;
  const changePinDialogIsOpenOrResetPin = dialogsState.changePinDialogIsOpen || hasToChangePin;
  const isCollaborator = role && role === roles.collaborator;

  const societySelectDialogOptions = useMemo(() => {
    return societies.map(society => ({
      ...society,
      key: society.number,
      value: society.name,
    }))
  }, [societies])

  const handleChangeSociety = (societyDbName) => {
    const newSociety = societies.find((society) => society.number === societyDbName);
    
    dialogsState.setChangeSocietyDialogIsOpen(false);
    dispatch(selectSociety(newSociety));
    history.push('/');
  }

  return (
    <>
      {
        registerAssistanceIsOpen &&
        <RegisterAssistanceDialog
          open={registerAssistanceIsOpen}
          assistanceData={assistanceData}
          handleClose={() => dialogsState.setAssistanceDialogIsOpen(false)}
        />
      }
      {
        changePinDialogIsOpenOrResetPin &&
        <ChangePinDialog
          open={changePinDialogIsOpenOrResetPin}
          onClose={() => dialogsState.setChangePinDialogIsOpen(false)}
          hideClose={hasToChangePin}
        />
      }
      {
        dialogsState.aboutUsDialogIsOpen &&
        <ModalAbout
          open={dialogsState.aboutUsDialogIsOpen}
          handleClose={() => dialogsState.setAboutUsDialogIsOpen(false)}
        />
      }
      {
        isCollaborator && dialogsState.myVacationsDialogIsOpen &&
        <MyVacations
          open={dialogsState.myVacationsDialogIsOpen}
          handleClose={() => dialogsState.setMyVacationsDialogIsOpen(false)}
        />
      }
      {
        isCollaborator && dialogsState.myCertificatesDialogIsOpen &&
        <MyCertificatesAndReceiptsDialog
          open={dialogsState.myCertificatesDialogIsOpen}
          isCertificate={true}
          handleClose={() => dialogsState.setMyCertificatesDialogIsOpen(false)}
        />
      }
      {
        isCollaborator && dialogsState.myReceiptsDialogIsOpen &&
        <MyCertificatesAndReceiptsDialog
          open={dialogsState.myReceiptsDialogIsOpen}
          isCertificate={false}
          handleClose={() => dialogsState.setMyReceiptsDialogIsOpen(false)}
        />
      }
      {
        isCollaborator && dialogsState.myHistoricalReceiptsDialogIsOpen &&
        <MyCertificatesAndReceiptsDialog
          open={dialogsState.myHistoricalReceiptsDialogIsOpen}
          isCertificate={false}
          isHistorical={true}
          handleClose={() => dialogsState.setMyHistoricalReceiptsDialogIsOpen(false)}
        />
      }
      {
        dialogsState.digitalDocumentsIsOpen &&
        <MyDigitalDocumentsDialog
          open={dialogsState.digitalDocumentsIsOpen}
          handleCloseDialog={() => dialogsState.setDigitalDocumentsIsOpen(false)}
        />
      }
      {
        dialogsState.requestDocumentsDialogIsOpen &&
        <RequestDocumentsDialog
          open={dialogsState.requestDocumentsDialogIsOpen}
          handleClose={() => dialogsState.setRequestDocumentsDialogIsOpen(false)}
        />
      }
      {
        societies && societies.length > 1 && dialogsState.changeSocietyDialogIsOpen &&
        <SocietySelectDialog
          open={dialogsState.changeSocietyDialogIsOpen}
          handleCloseDialog={() => dialogsState.setChangeSocietyDialogIsOpen(false)}
          handleCancelDialog={() => dialogsState.setChangeSocietyDialogIsOpen(false)} // en este caso es igual al close
          handleConfirm={handleChangeSociety}
          actualSociety={societySelected}
          options={societySelectDialogOptions}
        />
      }
      {
        dialogsState.timezoneDialogIsOpen &&
        <TimezoneDialog
          open={dialogsState.timezoneDialogIsOpen}
          handleClose={() => dialogsState.setTimezoneDialogIsOpen(false)}
        />
      }
    </>
  );
}

export default DialogContainer;
