import React from "react";
import { AssistanceTotem } from "@icarius-pages/assistanceTotem";
import { Fiscalization } from "@icarius-pages/fiscalization";
import { Login } from "@icarius-pages/";
import { Route, Switch } from "react-router-dom";


const OTHER_ROUTES = [
  {
    path: "/fiscalization",
    component: Fiscalization,
  },
  {
    path: "/registerAssistanceTotem",
    component: AssistanceTotem,
  },
]

const OuterRoutes = () => {

  return (
    <Switch>
      {
        OTHER_ROUTES.map(route =>
          <Route
            exact
            key={route.path}
            path={route.path}
            component={route.component}
          />
        )
      }
      <Route component={Login} />
    </Switch>
  );
}

export default OuterRoutes;