import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

const useRedirectPathNotFound = (routes) => {

  const history = useHistory();
  const location = useLocation();
  const path = location.pathname;

  useEffect(() => {
    const filteredRoutes = routes.filter(route => !route.isGroup);

    if (filteredRoutes.length && !filteredRoutes.find(item => item.path === path)) {
      history.push('/');
    }
  }, [history, path, routes])
}

export default useRedirectPathNotFound;