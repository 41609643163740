import React from "react";
import * as actionTypes from "./actionTypes";
import {
  SET_SOCIETIES_WITH_GETDATA,
  ASK_FOR_NEW_PASSWORD_FULFILLED,
  REQUEST_LOGIN_FULFILLED
} from "@icarius-pages/login/actionTypes";
import {
  getHasToChangePin,
  getLoginResponse,
  getSocietySelected,
} from "@icarius-pages/login/selectors";
import {
  getLocalizedString,
  getLocalizedErrorString,
} from "@icarius-localization/strings";
import {
  changePasswordAPI,
  getInitialDataAPI,
  setAppColor,
  getLogoApi,
  getDocumentsToRequestApi,
  getRequestDocumentApi,
  setPageAsVisitedAPI,
  exportExcelForIOS,
  registerAsistanceAPI,
} from "@icarius-connection/api";
import { setAuthInfo } from "@icarius-pages/login/sessionStorage";
import { logOutAction } from "@icarius-pages/login/actions";
import { openDialogAction } from "@icarius-common/dialog/actions";
import { downloadBlob } from "@icarius-utils/download";
import { setLocale } from "@icarius-table/utils";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";

const RE_FETCH_TIMEOUT = 5000;

export const resetAppState = (dispatch) => dispatch({ type: "RESET_REDUCERS" });

export const changePasswordAction = (currentPin, newPin) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.CHANGE_PASSWORD });
  try {
    let response = await changePasswordAPI({
      old_password: currentPin,
      new_password: newPin,
    });

    dispatch({
      type: actionTypes.CHANGE_PASSWORD_FULFILLED,
      payload: response.data,
    });

    if (getHasToChangePin(getState())) {
      dispatch({ type: ASK_FOR_NEW_PASSWORD_FULFILLED });
    }

    dispatch(
      openDialogAction({
        msg: getLocalizedString("pinCorrectlyChanged"),
        title: getLocalizedString("changingPin"),
        acceptOnly: true,
      })
    );
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.CHANGE_PASSWORD_REJECTED, error: e });
    throw e;
  }
};

export const getInitialDataAction = (society, token) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.GET_APP_DATA });
  let societyNumber = (society || (getSocietySelected(getState()) || {}).number);

  try {
    let response = await getInitialDataAPI(`?society_number=${societyNumber}`);
    let data = response.data;

    if (data && data.token) {
      setAuthInfo({ token: response.data.token });
    }

    dispatch({
      type: actionTypes.GET_APP_DATA_FULFILLED,
      payload: {
        alertsAmount: data.alertsAmount,
        assistanceData: data.assistanceData,
        associatedCountries: data.associatedCountries,
        availableMenus: data.availableMenus,
        avatar: data.avatar,
        contactData: data.contactData,
        connectedUsers: data.connectedUsers,
        digitalDocumentsAmount: data.digitalDocumentsAmount,
        isAdmin: data.isAdmin,
        divisions: data.divisions,
        isBlockedSocial: data.isBlockedSocial,
        isSocialEnabled: data.isSocialEnabled,
        isAbsentCollaboratorsEnabled: data.isAbsentCollaboratorsEnabled,
        managePermissions: data.managePermissions,
        manageVacations: data.manageVacations,
        society: data.society,
        user: data.user,
        userMenuData: data.userMenuData,
        connectedUsersEnabled: data.connectedUsersEnabled,
      }
    });

    if (society && token) {
      // en caso de que esten los datos (vendrian de la url) se setea el falso login
      dispatch({
        type: REQUEST_LOGIN_FULFILLED,
        payload: { user: data.user, token: token },
      });

      // Tengo que setear societies
      dispatch({
        type: SET_SOCIETIES_WITH_GETDATA,
        payload: data.societies,
      });
    }

    return response;
  } catch (e) {
    if (e.response.status === 503 && e.response.data.status === "SERVER_UNAVAIBLE") {
      const nodeToUse = (
        <>
          {e.response.data.data.stopMsg}
          <br />
          {e.response.data.data.stopTime}
        </>
      )

      dispatch({ type: actionTypes.GET_APP_DATA_REJECTED });
      dispatch(openDialogAction({
        title: "ICARIUS no está disponible en estos momentos",
        msg: nodeToUse,
        onConfirm: () => dispatch(logOutAction),
        acceptOnly: true
      }));

      return e.response.status;
    }

    if (getLoginResponse(getState())) {
      setTimeout(() => dispatch(getInitialDataAction()), RE_FETCH_TIMEOUT);
    }

    if ((e.response.status === 404 || e.response.status === 403)) {
      window.history.pushState({}, document.title, "/");
      dispatch(logOutAction);
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
    }

    return e;
  }
};

export const updateColorAction = (color) => async (dispatch) => {
  dispatch({ type: actionTypes.UPDATE_COLOR });
  try {
    let response = await setAppColor(color);

    dispatch({
      type: actionTypes.UPDATE_COLOR_FULFILLED,
      payload: response.data,
    });

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.UPDATE_COLOR_REJECTED });
    throw e;
  }
};

export const exportTableAction = (locale, title, params, settingsExcel) => async (dispatch) => {
  try {
    setLocale(locale);

    let isIOS =
      /iPad|iPhone|iPod/.test(navigator.platform) ||
      (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1);

    if (isIOS) {
      const csvData = params.api.getDataAsCsv(settingsExcel);

      dispatch({ type: actionTypes.EXPORT_ROWS_VIA_SERVER });
      try {
        let response = await exportExcelForIOS({ name: title, type: "xlsx", csv_data: csvData });
        if (response.data) {
          downloadBlob(response.data, "xlsx", title);
        }
        dispatch({ type: actionTypes.EXPORT_ROWS_VIA_SERVER_FULFILLED });
        return response;
      } catch (e) {
        dispatch({ type: actionTypes.EXPORT_ROWS_VIA_SERVER_REJECTED });
        throw e;
      }
    } else {
      params.api.exportDataAsExcel(settingsExcel);
    }
    return;
  } catch (e) {
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const getLogoFromServer = (theme) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_LOGO });
  try {
    let response = await getLogoApi(`?template=${theme}`);

    let logo = response.data && response.data.url;
    let status = response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_LOGO_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_LOGO_FULFILLED,
      payload: { logo },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_LOGO_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const getDocumentsToRequestAction = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET_DOCUMENTS_LIST });
  try {
    let response = await getDocumentsToRequestApi();
    let status = response.data && response.data.status;
    let docsToRequest = response.data && response.data.docsToRequest;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_DOCUMENTS_LIST_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_DOCUMENTS_LIST_FULFILLED,
      payload: { docsToRequest },
    });

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_DOCUMENTS_LIST_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const requestDocumentAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.REQUEST_DOCUMENT });
  try {
    let response = await getRequestDocumentApi(data);
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.REQUEST_DOCUMENT_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch(openSnackbarAction({ msg: getLocalizedString("documentRequestOK"), severity: "success" }));
    dispatch({ type: actionTypes.REQUEST_DOCUMENT_FULFILLED });

    return response;
  } catch (e) {
    if (e?.response?.data?.error === "DOCUMENT_DUPLICATED") {
      dispatch(openSnackbarAction({ msg: getLocalizedString("documentRequestDuplicated"), severity: "error" }));
    } else {
      dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    }
    dispatch({ type: actionTypes.REQUEST_DOCUMENT_REJECTED, payload: e });
  }
};

export const setPageAsVisitedAction = (data) => async () => {
  try {
    let response = await setPageAsVisitedAPI(data);
    return response;
  } catch (e) {
    return e;
  }
};

export const registerAsistanceAction = (type, workplace) => async (dispatch) => {
  dispatch({ type: actionTypes.REGISTER_ASSISTANCE });
  try {
    let response = await registerAsistanceAPI({
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      type,
      workplace,
    });

    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.REGISTER_ASSISTANCE_REJECTED });

      if (status === "ERROR_ASISTENCE") {
        dispatch(openSnackbarAction({ msg: getLocalizedErrorString("assistanceAlreadyDone"), severity: "warning" }));
      } else if (status === "BLOCKED") {
        dispatch(openSnackbarAction({ msg: getLocalizedErrorString("assistanceBlocked"), severity: "warning" }));
      } else {
        dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      }

      return response;
    }

    dispatch({
      type: actionTypes.REGISTER_ASSISTANCE_FULFILLED,
      payload: response.data,
    });

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.REGISTER_ASSISTANCE_REJECTED, payload: e });
    if (e.response.data.status === "ERROR_ASISTENCE") {
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("assistanceAlreadyDone"), severity: "warning" }));
    } else if (e.response.data.status === "BLOCKED") {
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("assistanceBlocked"), severity: "warning" }));
    } else {
      dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    }
  }
};