import React from "react";
import { useSelector } from "react-redux";
import { getAssistanceData, getAppColor } from "src/app/selectors";
import paths from "@icarius-localization/paths";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import { useHandleTime } from "@icarius-common/clock/useHandleTime";
import { MenuIcon, WatchLaterIcon } from "@icarius-icons";
import {
  AppBar,
  Toolbar,
  IconButton,
  Grid,
  useMediaQuery,
} from "@material-ui/core";
import Brightness2Icon from '@material-ui/icons/Brightness2';
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import IcariusLogo from "@icarius-assets/images/icariusLogo";
import { getLocalizedString } from "@icarius-localization/strings";
import {
  HIDE_SEARCH_HEADER_WIDTH_BREAKPOINT,
  HIDE_LOGO_HEADER_WIDTH_BREAKPOINT,
} from "@icarius-utils/constants";
import MenuSearch from "./menuSearch";
import TimezoneButton from "./timezoneButton";
import OnlineUsers from "./onlineUsers";
import Help from "./help";
import { getTheme } from "@icarius-pages/login/selectors";

const Header = (props) => {

  const {
    withDrawerToggle,
    routes,
    dialogsState,
    handleRegisterAssistance,
    handleToggleDrawer,
    handleToggleTheme,
    handleOpenItem,
    connectedUsersEnabled,
  } = props;

  useHandleTime();

  const color = useSelector(getAppColor);
  const { theme } = useSelector(getTheme);
  const assistanceData = useSelector(getAssistanceData);

  const shouldHideSearch = useMediaQuery(`(max-width:${HIDE_SEARCH_HEADER_WIDTH_BREAKPOINT - 1}px)`);
  const shouldHideLogo = useMediaQuery(`(max-width:${HIDE_LOGO_HEADER_WIDTH_BREAKPOINT - 1}px)`);

  const lightModeIsOn = theme === 'light';

  const handleOpenHome = () => {
    handleOpenItem({ path: paths.home });
    window.scrollTo({ top: 0 });
  }

  const handleOpenUtils = () => {
    handleOpenItem({ path: paths.utils });
    window.scrollTo({ top: 0 });
  }

  const handleOpenTimezoneDialog = () => dialogsState.setTimezoneDialogIsOpen(true)

  return (
    <AppBar position="fixed">
      <Toolbar disableGutters>
        <div
          className={"main-header"}
          style={{ borderBottom: `2px solid ${color}` }}
        >
          <Grid container direction="row" alignItems="center" style={{ paddingLeft: withDrawerToggle ? 0 : 60 }}>
            {
              withDrawerToggle &&
              <Grid item xs={shouldHideLogo ? 2 : 1}>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <IconButton onClick={handleToggleDrawer} color="inherit" aria-label="Menu">
                    <MenuIcon htmlColor={theme === "dark" ? "white" : "rgba(0, 0, 0, 0.54)"} />
                  </IconButton>
                </div>
              </Grid>
            }
            {
              !shouldHideSearch &&
              <Grid item xs={4} container direction="row" justify="flex-start" alignItems="center">
                <MenuSearch routes={routes} handleOpenItem={handleOpenItem} />
              </Grid>
            }
            {
              !shouldHideLogo &&
              <Grid
                container justify="center" item xs={!shouldHideSearch ? (withDrawerToggle ? 3 : 4) : 5}
                style={{ cursor: "pointer" }}
                onClick={handleOpenHome}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <IcariusLogo height={25} mainColor={color} theme={theme} />
                </div>
              </Grid>
            }
            <Grid item xs={shouldHideSearch ? (shouldHideLogo ? 10 : 6) : 4} container direction="row" justify="flex-end" alignItems="center">
              <Grid direction="row" container justify="center" item xs={2} alignItems="center">
                <CustomIconButton
                  title={'Descargas de utilidad'}
                  onClick={handleOpenUtils}
                  type={'download'}
                  noBorder
                />
              </Grid>
              <Grid direction="row" container justify="center" item xs={2} alignItems="center">
                <TimezoneButton handleOpenTimezoneDialog={handleOpenTimezoneDialog} />
              </Grid>
              {
                connectedUsersEnabled &&
                <Grid direction="row" container justify="center" item xs={2} alignItems="center">
                  <OnlineUsers />
                </Grid>
              }
              {
                assistanceData?.can_register_assistance &&
                <Grid direction="row" container justify="center" item xs={2} alignItems="center">
                  <CustomIconButton
                    title={getLocalizedString("registerAssistance")}
                    onClick={handleRegisterAssistance}
                    noBorder
                  >
                    <WatchLaterIcon />
                  </CustomIconButton>
                </Grid>
              }
              <Grid direction="row" container justify="center" item xs={2} alignItems="center">
                <CustomIconButton
                  title={lightModeIsOn ? 'Modo oscuro' : 'Modo claro'}
                  onClick={handleToggleTheme}
                  noBorder
                >
                  {
                    lightModeIsOn ?
                      <Brightness2Icon htmlColor={color} height="25px" />
                      :
                      <WbSunnyIcon htmlColor={color} height="25px" />
                  }
                </CustomIconButton>
              </Grid>
              <Grid container justify="center" item xs={2} style={{ height: "65px" }} alignItems="center">
                <Help />
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Toolbar>
    </AppBar>
  )
}

export default Header;