import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { getPageNameFromPath } from "@icarius-routes";

const useDocumentTitle = () => {

  const location = useLocation();
  const path = location.pathname;

  useEffect(() => {
    const pageName = getPageNameFromPath(path);

    if (pageName) {
      document.title = `ICARIUS - ${pageName}`;
      return;
    }

    document.title = 'ICARIUS';
  }, [path])
}

export default useDocumentTitle;