import NAME from "./constants";

export const CHANGE_PASSWORD = NAME + "/CHANGE_PASSWORD";
export const CHANGE_PASSWORD_FULFILLED = NAME + "/CHANGE_PASSWORD_FULFILLED";
export const CHANGE_PASSWORD_REJECTED = NAME + "/CHANGE_PIN_REJECTED";

export const SET_DIGITAL_DOCUMENT_QUANTITY = NAME + "/SET_DIGITAL_DOCUMENT_QUANTITY";

export const GET_APP_DATA = NAME + "/GET_APP_DATA";
export const GET_APP_DATA_FULFILLED = NAME + "/GET_APP_DATA_FULFILLED";
export const GET_APP_DATA_REJECTED = NAME + "/GET_APP_DATA_REJECTED";

export const UPDATE_COLOR = NAME + "/UPDATE_COLOR";
export const UPDATE_COLOR_FULFILLED = NAME + "/UPDATE_COLOR_FULFILLED";
export const UPDATE_COLOR_REJECTED = NAME + "/UPDATE_COLOR_REJECTED";

export const UPDATE_ALERTS_BADGE = NAME + "/UPDATE_ALERTS_BADGE";
export const UPDATE_AVATAR = NAME + "/UPDATE_AVATAR";
export const UPDATE_DIGITAL_DOCUMENTS_AMOUNT = NAME + "/UPDATE_DIGITAL_DOCUMENTS_AMOUNT";

export const GET_LOGO = NAME + "/GET_LOGO";
export const GET_LOGO_FULFILLED = NAME + "/GET_LOGO_FULFILLED";
export const GET_LOGO_REJECTED = NAME + "/GET_LOGO_REJECTED";

//Exportar desde grid
export const EXPORT_ROWS_VIA_SERVER = NAME + "/EXPORT_ROWS_VIA_SERVER";
export const EXPORT_ROWS_VIA_SERVER_FULFILLED = NAME + "/EXPORT_ROWS_VIA_SERVER_FULFILLED";
export const EXPORT_ROWS_VIA_SERVER_REJECTED = NAME + "/EXPORT_ROWS_VIA_SERVER_REJECTED";

//Request Documents
export const REQUEST_DOCUMENT = NAME + "/REQUEST_DOCUMENT";
export const REQUEST_DOCUMENT_FULFILLED = NAME + "/REQUEST_DOCUMENT_FULFILLED";
export const REQUEST_DOCUMENT_REJECTED = NAME + "/REQUEST_DOCUMENT_REJECTED";

export const GET_DOCUMENTS_LIST = NAME + "/GET_DOCUMENTS_LIST";
export const GET_DOCUMENTS_LIST_FULFILLED = NAME + "/GET_DOCUMENTS_LIST_FULFILLED";
export const GET_DOCUMENTS_LIST_REJECTED = NAME + "/GET_DOCUMENTS_LIST_REJECTED";

//Connected Users
export const SET_NEW_CONNECTED_USER = NAME + "/SET_NEW_CONNECTED_USER";
export const SET_NEW_DISCONNECTED_USER = NAME + "/SET_NEW_DISCONNECTED_USER";

// assistance
export const REGISTER_ASSISTANCE = NAME + "/REGISTER_ASSISTANCE";
export const REGISTER_ASSISTANCE_FULFILLED = NAME + "/REGISTER_ASSISTANCE_FULFILLED";
export const REGISTER_ASSISTANCE_REJECTED = NAME + "/REGISTER_ASSISTANCE_REJECTED";