import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setAuthInfo } from "@icarius-pages/login/sessionStorage";
import { selectSociety } from "@icarius-pages/login/actions";
import { getInitialDataAction } from "../actions";

// se fija si por url tiene token y sociedad, y con eso hace el getData
const useSkipLoginWithToken = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const token = new URLSearchParams(window.location.search).get("token");
    const societyNumber = new URLSearchParams(window.location.search).get("society_number");

    if (token) {
      setAuthInfo({ token });
      if (societyNumber) {
        setIsLoading(true);
        dispatch(selectSociety({ number: societyNumber }))
        dispatch(getInitialDataAction(societyNumber, token))
          .then(response => {
            dispatch(selectSociety(response.data.societies.find(e => e.number.toString() === societyNumber.toString())))
            setIsLoading(false);
          });
      }
    }
  }, [dispatch])

  return {
    isLoading
  };
}

export default useSkipLoginWithToken;