import React from "react";
import { useSelector } from 'react-redux'
import { CloseIcon, PlayCircleOutlineIcon } from "@icarius-icons";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Link,
  Grid,
  IconButton,
} from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import PaperDraggable from "@icarius-common/paperDraggable";
import { getTheme, getVersion } from "@icarius-pages/login/selectors";
import IcariusLogo from "@icarius-assets/images/icariusLogo";
import { getUserRole } from "src/app/selectors";
import DialogTransition from "@icarius-common/dialogTransition";

const ModalAbout = ({ open, handleClose }) => {

  const version = useSelector(getVersion);
  const theme = useSelector(getTheme);
  const level = useSelector(getUserRole);

  const handleOpenVideo = () => {
    let dlAnchorElem = document.getElementById("downloadAnchorElem");
    dlAnchorElem.setAttribute("href", "https://www.youtube.com/embed/GeXPlbMMeAs");
    dlAnchorElem.setAttribute("target", "_blank");
    dlAnchorElem.click();
  };

  return (
    <Dialog
      TransitionComponent={DialogTransition}
      open={open}
      onClose={handleClose}
      PaperComponent={PaperDraggable}
    >
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        {getLocalizedString("info")}
        <DialogTitleDivider />
      </DialogTitle>
      <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
      <DialogContent>
        <Grid container item justify="center" style={{ marginBottom: 30 }}>
          <IcariusLogo
            useLoginVersion
            theme={theme.theme}
            height={100}
          />
        </Grid>
        <div style={{ paddingBottom: 20, display: "flex", flexDirection: "column", alignItems: "center" }}>
          {
            level === 'E' &&
            <Link
              rel="noopener"
              variant="subtitle1"
              className="whiteText"
              href={"mailto:" + getLocalizedString("icariusMail")}
            >
              {getLocalizedString("icariusMail")}
            </Link>
          }
          <Link
            rel="noopener"
            target="_blank"
            variant="subtitle1"
            className="whiteText"
            href={"https://" + getLocalizedString("icariusWebsite")}
          >
            {getLocalizedString("icariusWebsite")}
          </Link>
          {
            level === 'E' &&
            <Link
              rel="noopener"
              target="_blank"
              variant="subtitle1"
              className="whiteText"
              href={"https://" + getLocalizedString("icariusForoWebsite")}
            >
              {getLocalizedString("icariusForoWebsite")}
            </Link>
          }
          <div style={{ display: 'flex', gap: 5, cursor: 'pointer' }} onClick={handleOpenVideo}>
            <IconButton size={"small"} disableRipple>
              <PlayCircleOutlineIcon className="whiteText" fontSize="small" />
            </IconButton>
            <Link variant={"subtitle1"} className={"whiteText"} >
              {getLocalizedString("icariusTech")}
            </Link>
          </div>
          <Typography variant="subtitle1" className="whiteText" align="center" style={{ paddingTop: 20 }}>
            {getLocalizedString("copyright").replace("{year}", new Date().getFullYear())}
          </Typography>
          <Typography variant="subtitle1" className="whiteText" align="center">
            {getLocalizedString("allRightsReserved")}
          </Typography>
          <Typography variant="subtitle1" className="whiteText" style={{ fontWeight: 600 }}>
            {`${getLocalizedString("version")}: ${version}`}
          </Typography>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default ModalAbout;
