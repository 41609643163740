import React from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Typography, Grid, useMediaQuery, Tooltip } from "@material-ui/core";
import { contactCols, XS_DEVICE_WIDTH_BREAKPOINT } from "@icarius-utils/constants";
import {
  TwitterIcon,
  FacebookIcon,
  LinkedinIcon,
  InstagramLogo,
  PhoneIcon,
  EnvelopeIcon,
  LocationIcon,
  SmartphoneIcon,
  ApartmentIcon,
} from "@icarius-icons";
import { getLocalizedString } from "@icarius-localization/strings";
import { getSocietySelected, getLoginResponse } from "@icarius-pages/login/selectors";
import { getAppColor, getContactData } from "src/app/selectors";

const Footer = ({ onSocietyClick }) => {

  const location = useLocation();

  const color = useSelector(getAppColor);
  const loginResponse = useSelector(getLoginResponse);
  const societySelected = useSelector(getSocietySelected);
  const contact = useSelector(getContactData);
  const matchesFooterIsNotHidden = useMediaQuery(`(min-width:${XS_DEVICE_WIDTH_BREAKPOINT}px)`);

  const mailSender = loginResponse.user.name;

  const iconStyle = {
    marginRight: "5px",
    width: "20px",
    height: "20px",
    fill: color,
  }

  const itemStyle = {
    margin: 0,
    padding: "5px 20px 0px 20px",
  };

  const changeSocietyStyle = {
    ...itemStyle,
    cursor: onSocietyClick && 'pointer',
  }

  const contactInfo = [
    {
      icon: <LocationIcon style={iconStyle} />,
      colName: contactCols.address,
      formatLink: address => address ? "https://www.google.com/maps/search/?api=1&query=" + address : "",
      target: "_blank",
      formatLabel: address => address,
    },
    {
      icon: <PhoneIcon style={iconStyle} />,
      colName: contactCols.phone,
      formatLink: tel => "tel:" + tel,
      target: "_blank",
      formatLabel: tel => tel,
    },
    {
      icon: <SmartphoneIcon style={iconStyle} />,
      colName: contactCols.cellphone,
      formatLink: tel => "tel:" + tel,
      target: "_blank",
      formatLabel: tel => tel,
    },
    {
      icon: <EnvelopeIcon style={iconStyle} />,
      colName: contactCols.email,
      formatLink: (mail, mailSender) => "mailto:" + mail + "?subject=" + getLocalizedString("messageFrom") + mailSender,
      target: "_blank",
      formatLabel: mail => mail,
    },
    {
      icon: <TwitterIcon style={iconStyle} />,
      colName: contactCols.twitter,
      formatLink: twitter => "https://twitter.com/" + twitter,
      target: "_blank",
      formatLabel: twitter => twitter,
    },
    {
      icon: <LinkedinIcon style={iconStyle} />,
      colName: contactCols.linkedin,
      formatLink: linkedin => "https://linkedin.com/" + linkedin,
      target: "_blank",
      formatLabel: linkedin => linkedin,
    },
    {
      icon: <FacebookIcon style={iconStyle} />,
      colName: contactCols.facebook,
      formatLink: fb => "https://facebook.com/" + fb,
      target: "_blank",
      formatLabel: fb => fb,
    },
    {
      icon: <InstagramLogo style={iconStyle} />,
      colName: contactCols.instagram,
      formatLink: ig => "https://instagram.com/" + ig,
      target: "_blank",
      formatLabel: ig => ig,
    },
  ];

  if (!color || !contactInfo || contactInfo?.length === 0 || !matchesFooterIsNotHidden || location.pathname !== '/') {
    return null;
  }

  return (
    <footer id="generalFooter" style={{ "borderTop": `2px solid ${color}` }}>
      <Grid style={{ margin: "0 auto", paddingBottom: "5px" }} container item xs={11} justify="center" alignItems="center">
        {
          societySelected &&
          <Tooltip
            title={
              <Typography style={{ fontSize: 14 }}>
                {societySelected.name}
              </Typography>
            }
          >
            <div style={changeSocietyStyle} className={"contactItem"} onClick={onSocietyClick}>
              <ApartmentIcon style={iconStyle} />
            </div>
          </Tooltip>
        }
        {
          contactInfo.map((info) => {
            let value = (contact.find(item => item.Key === info.colName) || {}).Value;

            if (!value) return null;
            return (
              <Tooltip
                key={value}
                title={
                  <Typography style={{ fontSize: 14 }}>
                    {info.formatLabel(value)}
                  </Typography>
                }
              >
                <a
                  style={itemStyle}
                  rel="noopener noreferrer"
                  target={info.target || ""}
                  className={"contactItem"}
                  href={info.formatLink(value, mailSender)}>
                  {info.icon}
                </a>
              </Tooltip>
            );
          })
        }
      </Grid>
    </footer >
  )
}

export default Footer;
