import React from "react";
import {
  Badge,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  List,
} from "@material-ui/core";
import { ExpandMore, ExpandLess } from "@icarius-icons";
import { useSelector } from "react-redux";
import { getTheme } from "@icarius-pages/login/selectors";
import { withStyles } from "@material-ui/core/styles";
import { HOVER_OPACITY } from "@icarius-utils/constants";
import { hexToRgb } from "@icarius-utils/colors";
import { getAppColor } from "src/app/selectors";

const MenuItem = (props) => {

  const {
    menuIsOpen,
    handleOpenMenu,
    subitems,
    icon,
    label,
    amount,
    onClick,
  } = props;

  const color = useSelector(getAppColor);
  const { theme } = useSelector(getTheme);
  const hasChildren = Boolean(subitems?.length);

  const CustomListItem = withStyles({
    button: {
      "&:hover": {
        backgroundColor: hexToRgb(color, HOVER_OPACITY),
      },
    },
  })(ListItem);

  const CustomBadge = withStyles({
    badge: {
      backgroundColor: color,
      color: "white",
    },
  })(Badge);

  const getIcon = () => {
    if (!amount) return icon;

    return (
      <CustomBadge badgeContent={amount} max={9}>
        {icon}
      </CustomBadge>
    )
  }

  const getExpandIcon = (isOpen) => {
    if (!hasChildren) return null;

    const htmlColor = theme === "dark" ? "white" : "rgba(0, 0, 0, 0.54)";
    if (isOpen) return <ExpandLess htmlColor={htmlColor} />;
    return <ExpandMore htmlColor={htmlColor} />
  }

  return (
    <>
      <CustomListItem
        button
        style={{ whiteSpace: hasChildren ? "nowrap" : "" }}
        onClick={handleOpenMenu || onClick}
      >
        <ListItemIcon style={{ minWidth: 45 }}>{getIcon()}</ListItemIcon>
        <ListItemText className={hasChildren ? "item-header-label" : "item-label"} primary={label} />
        {getExpandIcon(menuIsOpen)}
      </CustomListItem>
      {
        hasChildren &&
        <Collapse in={menuIsOpen} timeout="auto" unmountOnExit>
          <List className="whiteText" component="div" disablePadding>
            {
              subitems.map((item, index) => {
                return (
                  <MenuItem
                    key={index}
                    icon={item.icon}
                    label={item.title}
                    onClick={() => onClick(item)}
                  />
                )
              })
            }
          </List>
        </Collapse>
      }
    </>
  )
}
export default MenuItem;