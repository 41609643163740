import { useMemo, useCallback } from "react";
import { useSelector } from "react-redux";
import { getUserRole, getAvailableMenus, getUserMenuData } from "src/app/selectors";
import { getAllRoutes, getNavRoutes } from "@icarius-routes";
import UserMenu from "@icarius-pages/userMenu/components";
import paths from "@icarius-localization/paths";

const useGetRoutes = () => {

  const availableMenus = useSelector(getAvailableMenus);
  const userMenuData = useSelector(getUserMenuData);
  const role = useSelector(getUserRole);

  const getDynamicRouteObject = useCallback((route, index) => {
    return ({
      component: UserMenu,
      path: paths.userMenu,
      title: route.name,
      search: `index=${index}`,
    })
  }, [])

  const allRoutes = useMemo(() => {
    return [
      ...getAllRoutes(role, availableMenus),
      ...userMenuData
        .sort((a, b) => b.name.localeCompare(a.name))
        .map((item, index) => getDynamicRouteObject(item, index)),  //agregar las dinamicas para que el router las reconozca
    ];
  }, [getDynamicRouteObject, role, availableMenus, userMenuData]);

  const customRoutes = useMemo(() => {
    const navRoutes = getNavRoutes(role, availableMenus);
    // agregar las dinamicas para que salgan en el menu
    userMenuData
      .sort((a, b) => b.name.localeCompare(a.name)) // se ordena por nombre decreciente porque se van agregando tipo pila
      .forEach((dynamicTable, index) => {
        for (const [groupIndex, group] of navRoutes.entries()) {

          // ver si va primero (pegado al group parent)
          const newRoute = getDynamicRouteObject(dynamicTable, index);
          if (group.parent.path === dynamicTable.siblingPath) {
            navRoutes[groupIndex].children.splice(0, 0, newRoute);
            break;
          }

          // ver si va despues de algun children
          const childrenFoundIndex = group.children.findIndex((item) => item.path === dynamicTable.siblingPath);
          if (childrenFoundIndex !== -1) {
            navRoutes[groupIndex].children.splice(childrenFoundIndex + 1, 0, newRoute);
            break;
          }
        }
      });

    return navRoutes;
  }, [getDynamicRouteObject, role, availableMenus, userMenuData]);

  return {
    availableMenus,
    allRoutes,
    customRoutes,
  }
}

export default useGetRoutes;
