import { useState } from "react";

const useDrawerDialogsState = () => {
  const [digitalDocumentsIsOpen, setDigitalDocumentsIsOpen] = useState(false);
  const [changePinDialogIsOpen, setChangePinDialogIsOpen] = useState(false);
  const [aboutUsDialogIsOpen, setAboutUsDialogIsOpen] = useState(false);
  const [myCertificatesDialogIsOpen, setMyCertificatesDialogIsOpen] = useState(false);
  const [myReceiptsDialogIsOpen, setMyReceiptsDialogIsOpen] = useState(false);
  const [myHistoricalReceiptsDialogIsOpen, setMyHistoricalReceiptsDialogIsOpen] = useState(false);
  const [changeSocietyDialogIsOpen, setChangeSocietyDialogIsOpen] = useState(false);
  const [myVacationsDialogIsOpen, setMyVacationsDialogIsOpen] = useState(false);
  const [requestDocumentsDialogIsOpen, setRequestDocumentsDialogIsOpen] = useState(false);
  const [assistanceDialogIsOpen, setAssistanceDialogIsOpen] = useState(false);
  const [timezoneDialogIsOpen, setTimezoneDialogIsOpen] = useState(false);

  return {
    digitalDocumentsIsOpen,
    setDigitalDocumentsIsOpen,
    changePinDialogIsOpen,
    setChangePinDialogIsOpen,
    aboutUsDialogIsOpen,
    setAboutUsDialogIsOpen,
    myCertificatesDialogIsOpen,
    setMyCertificatesDialogIsOpen,
    myReceiptsDialogIsOpen,
    setMyReceiptsDialogIsOpen,
    myHistoricalReceiptsDialogIsOpen,
    setMyHistoricalReceiptsDialogIsOpen,
    changeSocietyDialogIsOpen,
    setChangeSocietyDialogIsOpen,
    myVacationsDialogIsOpen,
    setMyVacationsDialogIsOpen,
    requestDocumentsDialogIsOpen,
    setRequestDocumentsDialogIsOpen,
    assistanceDialogIsOpen,
    setAssistanceDialogIsOpen,
    timezoneDialogIsOpen,
    setTimezoneDialogIsOpen,
  };
}

export default useDrawerDialogsState;