import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import { MuiThemeProvider } from "@material-ui/core/styles";
import Loader from "@icarius-common/loader";
import { isKeyPresent, getValueFromKey } from "@icarius-utils/localStorage";
import { createTheme } from "@icarius-utils/theme";
import { getHasToChangePin, getIsAuthorized, getTheme } from "@icarius-pages/login/selectors";
import useSkipLoginWithToken from "./useSkipLoginWithToken";
import Wrapper from "./wrapper";
import OuterRoutes from "./outerRoutes";
import { Snackbar } from "@icarius-common/snackbar";
import { Dialog } from "@icarius-common/dialog";

const App = () => {
  // limpiar el state del history para evitar bugs en pantallas con redirect
  window.history.replaceState({}, document.title)

  useEffect(() => {
    // setear el del localStorage (o dark) como inicial hasta que el Login obtenga el del cliente
    document.documentElement.setAttribute('data-theme', isKeyPresent("icarius-theme") ? getValueFromKey("icarius-theme") : 'dark');
  }, [])

  const { isLoading } = useSkipLoginWithToken();
  const auth = useSelector(getIsAuthorized);
  const hasToChangePin = useSelector(getHasToChangePin);
  const theme = useSelector(getTheme);

  return (
    <MuiThemeProvider theme={createTheme(theme?.theme)}>
      <BrowserRouter>
        <Loader open={isLoading} />
        {
          (auth && !hasToChangePin) ?
            <Wrapper />
            :
            <OuterRoutes />
        }
      </BrowserRouter>
      <Snackbar />
      <Dialog />
    </MuiThemeProvider>
  );
}

export default App;