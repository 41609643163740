import React from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import {
  GroupsIcon,
  PowerOffButton,
  PersonalSheet,
  OpenLock,
  ShieldAlert,
  DirectoyButton,
  InsertDriveFileIcon,
  WarehouseIcon,
  ApartmentIcon,
} from "@icarius-icons";
import paths from "@icarius-localization/paths";

const DEFAULT_LIST_ITEMS = [
  {
    title: getLocalizedString("changeSociety"),
    icon: <ApartmentIcon className={"icon"} />,
    path: paths.changeSociety,
  },
  {
    title: getLocalizedString("myGroupsTitle"),
    icon: <GroupsIcon />,
    path: paths.myGroups,
  },
  {
    title: getLocalizedString("directory"),
    icon: <DirectoyButton />,
    path: paths.directory,
  },
  {
    title: getLocalizedString("alerts"),
    icon: <ShieldAlert />,
    path: paths.alerts,
  },
  {
    title: getLocalizedString("digitalDocuments"),
    icon: <InsertDriveFileIcon />,
    path: paths.digitalDocuments,
  },
  {
    title: 'Mi bodega digital',
    icon: <WarehouseIcon />,
    path: paths.digitalWarehouse,
  },
  {
    title: getLocalizedString("myPin"),
    icon: <OpenLock />,
    path: paths.myPIN,
  },
  {
    title: getLocalizedString("myPersonalData"),
    icon: <PersonalSheet />,
    path: paths.myPersonalData
  },
  {
    title: getLocalizedString("closeSession"),
    icon: <PowerOffButton />,
    path: paths.logOut,
  },
];

export default DEFAULT_LIST_ITEMS;