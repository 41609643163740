import React from "react";
import { Popover, useMediaQuery } from "@material-ui/core";
import { themeColors } from "@icarius-utils/colors";
import { useSelector } from "react-redux";
import { getTheme } from "@icarius-pages/login/selectors";
import { MD_DEVICE_WIDTH_BREAKPOINT } from "@icarius-utils/constants";

const UsersList = (props) => {

    const {
        open,
        anchorEl,
        onClose,
        children,
    } = props;

    const { theme } = useSelector(getTheme);
    const mobile = useMediaQuery(`(max-width:${MD_DEVICE_WIDTH_BREAKPOINT}px)`);

    return (
        <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
                vertical: mobile ? "top" : "bottom",
                horizontal: mobile ? "center" : "right",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            PaperProps={{
                style: {
                    boxShadow: "none",
                    padding: 20,
                    width: mobile ? "100vw" : "350px",
                    border: theme === "dark" ? `1px solid ${themeColors.borderTransparentDark}` : `1px solid ${themeColors.borderTransparentLight}`,
                    background: "var(--secondaryBackgroundColor)",
                }
            }}
        >
            {children}
        </Popover>
    );
}

export default UsersList;