import React from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import { roleNames } from "@icarius-utils/constants";
import { Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import { getAvatar, getIsAdmin, getUserData } from "src/app/selectors";
import ProfileImg from "@icarius-common/profileImg";

const DrawerUser = () => {

  const { firstName, level } = useSelector(getUserData);
  const isAdmin = useSelector(getIsAdmin);
  const avatar = useSelector(getAvatar);

  const salute = firstName !== "" ? getLocalizedString("headerSaluteWithName").replace("{name}", firstName) : getLocalizedString("headerSaluteNoName");
  const roleText = (isAdmin ? 'Empleador Administrador' : getLocalizedString("portal").replace("{role}", roleNames[level])).toUpperCase();

  return (
    <div style={{ minWidth: 272, display: "flex" }}>
      <div style={{ height: 63, width: 60, display: "flex", justifyContent: "center", alignItems: "center" }} className={"photo-container small drawerPhoto"}>
        <ProfileImg image={avatar} size={45} />
      </div>
      <div style={{ display: "flex", flexDirection: "column", paddingLeft: 10, justifyContent: "space-evenly" }}>
        <Typography style={{ fontWeight: 300, fontSize: '20px', lineHeight: "20px" }} className={"whiteText"} variant="subtitle1">
          {salute}
        </Typography>
        <Typography className={"whiteText"} style={{ fontWeight: 500, fontSize: "14px", lineHeight: "13px" }}>
          {roleText}
        </Typography>
      </div>
    </div>
  );
}

export default DrawerUser;
