import React, { useState } from "react";
import { useSelector } from "react-redux";
import { getLocalizedString } from "@icarius-localization/strings";
import { Typography, Grid } from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import { getConnectedUsers } from "src/app/selectors";
import UsersList from "./usersList";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import { PersonIcon } from "@icarius-icons/index";

const RoleContainer = ({ title, children }) => {

  if (!children || children.length === 0) return null;

  return (
    <div style={{ marginTop: 10 }}>
      <Typography style={{ fontWeight: 400 }} className="whiteText">
        {title}
      </Typography>
      <div style={{ paddingBottom: 6 }}>
        <DialogTitleDivider />
      </div>
      {children}
    </div>
  )
}

const getContentByRole = (roleData) => {
  return (
    roleData?.map((user, index) => {
      return (
        <Grid key={index} container item alignItems="center" style={{ height: 36 }}>
          <div
            style={{
              height: 8,
              width: 8,
              backgroundColor: "#18ab00",
              borderRadius: "50%",
              display: "inline-block",
            }}
          />
          <Typography style={{ paddingLeft: 8, fontWeight: 100 }} className="whiteText">
            {user.name}
          </Typography>
        </Grid>
      )
    })
  )
}

const OnlineUsers = React.memo(() => {

  const [anchorEl, setAnchorEl] = useState(null);

  const connectedUsers = useSelector(getConnectedUsers);
  const onlineAmount = (connectedUsers.C?.length + connectedUsers.M?.length + connectedUsers.E?.length) || 0;

  return (
    <>
      <CustomIconButton
        title={getLocalizedString("onlineUsers")}
        onClick={(event) => setAnchorEl(event.currentTarget)}
        noBorder
      >
        <PersonIcon />
      </CustomIconButton>
      {
        connectedUsers &&
        <UsersList
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
        >
          <Grid container item alignItems="center">
            <div
              style={{
                height: 8,
                width: 8,
                backgroundColor: "#18ab00",
                borderRadius: "50%",
                display: "inline-block",
              }}
            />
            <Typography style={{ paddingLeft: 8, fontWeight: 400, marginBottom: 10 }} className="whiteText">
              {`${getLocalizedString("onlineUsers")} (${onlineAmount})`}
            </Typography>
          </Grid>
          {
            <div style={{ maxHeight: 350, overflow: "auto" }}>
              <RoleContainer title={getLocalizedString("onlineCollab")}>
                {getContentByRole(connectedUsers["C"])}
              </RoleContainer>
              <RoleContainer title={getLocalizedString("onlineManagers")}>
                {getContentByRole(connectedUsers["M"])}
              </RoleContainer>
              <RoleContainer title={getLocalizedString("onlineEmployers")}>
                {getContentByRole(connectedUsers["E"])}
              </RoleContainer>
            </div>
          }
        </UsersList>
      }
    </>
  );
})

export default OnlineUsers;