import NAME from "./constants";

export const getIsLoading = store => store[NAME].isLoading;
export const getIsExportingGrid = store => store[NAME].isExportingGrid;
export const getIsLoadingDocumentsToRequest = store => store[NAME].isLoadingDocumentsToRequest;
export const getIsLoadingRegisterAssistance = store => store[NAME].isLoadingRegisterAssistance;

export const getAlertsAmount = store => store[NAME].alertsAmount;
export const getAppColor = store => store[NAME].society?.color;
export const getCountry = store => store[NAME].society?.country;
export const getSocietyTimezone = store => store[NAME].society?.timezone;
export const getAssistanceData = store => store[NAME].assistanceData;
export const getAssociatedCountries = store => store[NAME].associatedCountries;
export const getAvailableMenus = store => store[NAME].availableMenus;
export const getAvatar = store => store[NAME].avatar;
export const getConnectedUsers = store => store[NAME].connectedUsers;
export const getContactData = store => store[NAME].contactData;
export const getDigitalDocumentsAmount = store => store[NAME].digitalDocumentsAmount;
export const getDocumentsToRequestList = store => store[NAME].docsToRequest;
export const getIsAdmin = store => store[NAME].isAdmin;
export const getGeographicalDivisions = store => store[NAME].divisions;
export const getIsBlockedSocial = store => store[NAME].isBlockedSocial;
export const getIsSocialEnabled = store => store[NAME].isSocialEnabled;
export const getIsAbsentCollaboratorsEnabled = store => store[NAME].isAbsentCollaboratorsEnabled;
export const getLogo = store => store[NAME].logo;
export const getManagePermissions = store => store[NAME].managePermissions;
export const getManageVacations = store => store[NAME].manageVacations;
export const getUserData = store => store[NAME].user;
export const getUserRole = store => store[NAME].user?.level;
export const getEmployeeName = store => store[NAME].user?.name;
export const getUserMenuData = store => store[NAME].userMenuData;
export const getConnectedUsersEnabled = store => store[NAME].connectedUsersEnabled;