import React, { useState, useEffect, useRef } from "react";
import { Dialog, DialogTitle, DialogContent } from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import PaperDraggable from "@icarius-common/paperDraggable";
import { getAssociatedCountries } from "src/app/selectors";
import { CloseIcon, ArrowLeft, ArrowRight } from "@icarius-icons";
import { useSelector } from "react-redux";
import { IconButton } from "@material-ui/core";
import { getAppColor } from "src/app/selectors"
import Swiper from "react-id-swiper";
import TimezoneItem from "./timezoneItem";
import DialogTransition from "@icarius-common/dialogTransition";

const swiperParams = {
  autoplay: {
    delay: 3000,
    disableOnInteraction: false,
  },
  grabCursor: true,
  rebuildOnUpdate: true,
  containerClass: "main-content-swiper-container",
  slidesPerView: 5,
  spaceBetween: 20,
  breakpoints: {
    1500: {
      slidesPerView: 5,
      spaceBetween: 20,
    },
    1100: {
      slidesPerView: 4,
      spaceBetween: 20,
    },
    820: {
      slidesPerView: 2,
      spaceBetween: 10,
    },
    650: {
      slidesPerView: 1,
      spaceBetween: 10,
    },
  },
};

const useFixWidth = (items) => {
  // cuando carga items que fuerce un rerender para que el swiper no se rompa con el ancho
  const [, setCount] = useState(0);
  useEffect(() => {
    if (items) {
      setCount(prev => prev + 1)
    }
  }, [items])
}

const TimezoneDialog = ({ open, handleClose }) => {

  const associatedCountries = useSelector(getAssociatedCountries);

  useFixWidth(associatedCountries);
  const color = useSelector(getAppColor);
  const swiper = useRef(null);

  const goNext = () => {
    if (swiper.current) swiper.current.slideNext();
  };

  const goPrev = () => {
    if (swiper.current) swiper.current.slidePrev();
  };

  if (!associatedCountries || associatedCountries?.length === 0) return null;

  return (
    <Dialog
      TransitionComponent={DialogTransition}
      open={open}
      PaperComponent={PaperDraggable}
      maxWidth={'lg'}
      fullWidth
    >
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        {getLocalizedString("timeZones")}
        <DialogTitleDivider />
      </DialogTitle>
      <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
      <DialogContent style={{ padding: 0 }}>
        <section id={"main-page"}>
          <div className={"slider-container"}>
            <IconButton className="custom-swiper-button custom-swiper-button-left" onClick={goPrev}>
              <ArrowLeft className={"swiper-button-icon"} style={{ color: color }} />
            </IconButton>
            <IconButton className="custom-swiper-button custom-swiper-button-right" onClick={goNext}>
              <ArrowRight className={"swiper-button-icon"} style={{ color: color }} />
            </IconButton>
            <Swiper
              {...swiperParams}
              ref={node => {
                if (node) swiper.current = node.swiper;
              }}
              className={"swiper-wrapper"}
            >
              {
                associatedCountries?.map((item, index) => (
                  <div className={"items-slider-container"} key={index}>
                    <div style={{ background: 'var(--secondaryBackgroundColor)', borderRadius: 14, padding: '8px 0px 16px' }}>
                      <TimezoneItem data={item} />
                    </div>
                  </div>
                ))
              }
            </Swiper>
          </div>
        </section>
      </DialogContent>
    </Dialog>
  );
}

export default TimezoneDialog;
