import React from "react";
import { IMAGES_ENDPOINT } from "@icarius-connection/endpoints";
import { getLogo } from "src/app/selectors";
import { useSelector } from "react-redux";
import paths from "@icarius-localization/paths";
import { useHistory } from "react-router-dom";

const DrawerLogo = ({ handleCloseDrawer }) => {

  const history = useHistory();
  const logo = useSelector(getLogo);

  return (
    <div id={"drawer-logo-container"}>
      {
        Boolean(logo) ?
          <img
            src={IMAGES_ENDPOINT + logo}
            id={"logo"}
            alt="Main Logo"
            onClick={() => {
              history.push(paths.home);
              handleCloseDrawer();
            }}
          />
          : null
      }
    </div>
  );
}

export default DrawerLogo;
