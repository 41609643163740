import React, { useState } from "react";
import MenuItem from "./menuItem";
import { List } from "@material-ui/core";

const DrawerCustomItems = ({ data, handleOpenItem }) => {

  const [menuItemToOpen, setMenuItemToOpen] = useState(null);

  return (
    <List>
      {
        data.map((group, index) => (
          <MenuItem
            key={index}
            icon={group.parent.icon}
            label={group.parent.title}
            subitems={group.children}
            menuIsOpen={index === menuItemToOpen}
            handleOpenMenu={() => setMenuItemToOpen(prev => prev === index ? null : index)}
            onClick={handleOpenItem}
          />
        ))
      }
    </List>
  );
}

export default DrawerCustomItems;
