import React from "react";
import Typography from "@material-ui/core/Typography/";
import { Grid } from "@material-ui/core";
import { getTime } from "@icarius-common/clock/selectors";
import { useSelector } from "react-redux";

const TimezoneItem = ({ data }) => {

  const time = useSelector(getTime);

  return (
    <Grid
      style={{
        paddingTop: 12,
        paddingLeft: 24,
        paddingRight: 24,
      }}
      container
      direction={"column"}
      justify="center"
      alignItems="flex-start"
      item
    >
      <Typography
        className="whiteText"
        style={{
          "fontFamily": "Roboto",
          "fontStyle": "normal",
          "fontWeight": "500",
          "fontSize": "14px",
          "lineHeight": "16px",
          "paddingBottom": "8px",
        }}
      >
        {data.country}
      </Typography>
      <Typography
        className="whiteText"
        style={{
          "fontFamily": "Roboto",
          "fontStyle": "normal",
          "fontWeight": "200",
          "fontSize": "14px",
          "lineHeight": "16px",
          "paddingBottom": "8px",
        }}
      >
        {data.name}
      </Typography>
      <Typography
        className="whiteText"
        style={{
          "fontFamily": "Roboto",
          "fontStyle": "normal",
          "fontWeight": "normal",
          "fontSize": "24px",
          "lineHeight": "24px",
        }}
      >
        {
          new Date(time).toLocaleTimeString("en-US", {
            timeZone: data.timezone,
            hour12: false,
          })
        }
      </Typography>
    </Grid>
  );
}

export default TimezoneItem;
