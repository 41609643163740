import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  startConnection,
  stopConnection,
  listenToSocket,
  stopListenToSocket,
} from "@icarius-connection/webSocketHandlers";
import paths from "@icarius-localization/paths";
import { getVoiceGenreAction } from "@icarius-common/audioPlayer/actions";
import { getSocieties, getLoginResponse, getSocietySelected } from "@icarius-pages/login/selectors";
import { killTokens, logOutAction } from "@icarius-pages/login/actions";
import { getAppColor, getIsLoading, getConnectedUsersEnabled } from "src/app/selectors";
import { exportTableAction, getInitialDataAction } from "src/app/actions";
import { setExportToExcelFunction, setSocietyName } from "@icarius-table/utils";
import useVisitPage from "@icarius-utils/hooks/useVisitPage";
import useGetRoutes from "./useGetRoutes";
import useDocumentTitle from "./useDocumentTitle";
import useRedirectPathNotFound from "./useRedirectPathNotFound";
import useDrawerDialogsState from "./useDrawerDialogsState";
import { setModalIsNotVisited } from "@icarius-pages/home/actions";

const useWrapper = () => {

  const [drawerIsOpen, setDrawerIsOpen] = useState(false);

  const {
    availableMenus,
    allRoutes,
    customRoutes,
  } = useGetRoutes();

  const dispatch = useDispatch();
  const isLoading = useSelector(getIsLoading);
  const loginResponse = useSelector(getLoginResponse);
  const history = useHistory();
  const dialogsState = useDrawerDialogsState();
  const societies = useSelector(getSocieties);
  const color = useSelector(getAppColor);
  const societySelected = useSelector(getSocietySelected);
  const connectedUsersEnabled = useSelector(getConnectedUsersEnabled);

  useEffect(() => {
    dispatch(setModalIsNotVisited());
    dispatch(getInitialDataAction())
      .then((resp) => {
        if (resp.status === 200) {
          dispatch(getVoiceGenreAction());
        }
      })

    startConnection(loginResponse.token, dispatch);
    listenToSocket(dispatch);

    // seteos del agGrid
    setExportToExcelFunction((...args) => dispatch(exportTableAction(...args)));
    setSocietyName(societySelected.name);

    return () => {
      stopListenToSocket();
      stopConnection();
    }
  }, [dispatch, loginResponse.token, societySelected.name])

  useEffect(() => {
    document.documentElement.style.setProperty("--corporate-color", color); // setear variable de css con el color corporativo (para usarla en css de grillas)
  }, [color])

  useVisitPage();
  useDocumentTitle();
  useRedirectPathNotFound(allRoutes);

  const handleOpenItem = (item) => {
    switch (item?.path) {
      case paths.myReceipts: {
        dialogsState.setMyReceiptsDialogIsOpen(true);
        break;
      }
      case paths.myHistoricalReceipts: {
        dialogsState.setMyHistoricalReceiptsDialogIsOpen(true);
        break;
      }
      case paths.myCertificates: {
        dialogsState.setMyCertificatesDialogIsOpen(true);
        break;
      }
      case paths.myVacations: {
        dialogsState.setMyVacationsDialogIsOpen(true);
        break;
      }
      case paths.requestDocuments: {
        dialogsState.setRequestDocumentsDialogIsOpen(true);
        break;
      }
      case paths.digitalDocuments: {
        dialogsState.setDigitalDocumentsIsOpen(true);
        break;
      }
      case paths.myPIN: {
        dialogsState.setChangePinDialogIsOpen(true);
        break;
      }
      case paths.changeSociety: {
        dialogsState.setChangeSocietyDialogIsOpen(true);
        break;
      }
      case paths.aboutUs: {
        dialogsState.setAboutUsDialogIsOpen(true);
        break;
      }
      case paths.logOut: {
        dispatch(logOutAction);
        dispatch(killTokens(loginResponse.token));
        history.push('');
        break;
      }
      default: {
        history.push({
          pathname: item.path,
          search: item.search,
        });
        break;
      }
    }
  };

  const canChangeSociety = societies?.length > 1 && availableMenus?.includes(paths.changeSociety.replace("/", ""));

  return {
    isLoading,
    color,
    availableMenus,
    allRoutes,
    customRoutes,
    canChangeSociety,
    dialogsState,
    drawerIsOpen,
    setDrawerIsOpen,
    handleOpenItem,
    connectedUsersEnabled,
  }
}

export default useWrapper;