import { useMemo } from "react";
import { PATHS_TO_IGNORE_IN_SEARCH } from "@icarius-localization/paths";
import useResetStateByKey from "@icarius-utils/hooks/useResetStateByKey";

const myPinRoute = {
  title: 'Mi PIN',
  path: '/myPin',
}

const aboutUsRoute = {
  title: 'Acerca de ICARIUS',
  path: '/aboutUs',
}

const replaceAccents = (str) => str.normalize("NFD").replace(/([aeio])\u0301|(u)[\u0301\u0308]/gi, "$1$2");

const useMenuSearch = (routes, handleOpenItem) => {

  //key que hace que cuando se seleccione una opcion, se rerenderice el AutoComplete y se limpie el campo
  const {
    key,
    resetState,
  } = useResetStateByKey();

  const options = useMemo(() => {
    const auxRoutes = [...routes, aboutUsRoute, myPinRoute];
    return auxRoutes
      .filter(route => !route.isGroup && !PATHS_TO_IGNORE_IN_SEARCH.includes(route.path))
      .sort((a, b) => -b.title?.localeCompare(a.title));
  }, [routes])

  const filterOptions = (options, state) => {
    if (state.inputValue === "") return [];

    const auxArr = [];

    options.forEach((option) => {
      const originalTextMatches = option?.title?.toLowerCase().includes(state.inputValue.toLocaleLowerCase());
      const accentlessTextMatches = replaceAccents(option?.title?.toLowerCase() || '').includes(state.inputValue.toLocaleLowerCase());
      const pathMatches = option?.path?.toLowerCase().includes(state.inputValue.toLocaleLowerCase());

      if (originalTextMatches || accentlessTextMatches || pathMatches) {
        auxArr.push(option);
      }
    });

    return auxArr;
  }

  const onChange = (_, selected) => {
    selected && handleOpenItem(selected);
    resetState();
  }

  const getOptionLabel = (route) => route.title || route.string;
  const getOptionSelected = (route, selected) => route.path === selected.path;

  return {
    key,
    options,
    filterOptions,
    onChange,
    getOptionLabel,
    getOptionSelected
  }
}

export default useMenuSearch;
