import * as actionTypes from "./actionTypes";

const initialState = {
  isExportingGrid: false,
  isLoading: false,
  isLoadingDocumentsToRequest: false,
  isLoadingRegisterAssistance: false,

  docsToRequest: [],
  logo: "",
  divisions: { firstLevel: "", secondLevel: "", thirdLevel: "" },

  appColor: null,
  alertsAmount: 0,
  assistanceData: null,
  associatedCountries: [],
  availableMenus: [],
  avatar: "",
  contactData: [],
  connectedUsers: {},
  digitalDocumentsAmount: 0,
  isAdmin: false,
  isBlockedSocial: false,
  isSocialEnabled: false,
  isAbsentCollaboratorsEnabled: false,
  managePermissions: false,
  manageVacations: false,
  society: null,
  user: null,
  userMenuData: [],
  connectedUsersEnabled: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_APP_DATA:
      return { ...state, isLoading: true };
    case actionTypes.GET_APP_DATA_FULFILLED:
      return {
        ...state,
        isLoading: false,
        alertsAmount: action.payload.alertsAmount,
        assistanceData: action.payload.assistanceData,
        associatedCountries: action.payload.associatedCountries,
        availableMenus: action.payload.availableMenus,
        avatar: action.payload.avatar,
        contactData: action.payload.contactData,
        connectedUsers: action.payload.connectedUsers,
        digitalDocumentsAmount: action.payload.digitalDocumentsAmount,
        isAdmin: action.payload.isAdmin,
        divisions: action.payload.divisions,
        isBlockedSocial: action.payload.isBlockedSocial,
        isSocialEnabled: action.payload.isSocialEnabled,
        isAbsentCollaboratorsEnabled: action.payload.isAbsentCollaboratorsEnabled,
        managePermissions: action.payload.managePermissions,
        manageVacations: action.payload.manageVacations,
        society: action.payload.society,
        user: action.payload.user,
        userMenuData: action.payload.userMenuData || [],
        connectedUsersEnabled: action.payload.connectedUsersEnabled,
      };
    case actionTypes.GET_APP_DATA_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.SET_DIGITAL_DOCUMENT_QUANTITY:
      return { ...state, digitalDocumentsQuantity: action.payload };

    case actionTypes.UPDATE_COLOR:
      return { ...state, isLoading: true };
    case actionTypes.UPDATE_COLOR_FULFILLED:
      return {
        ...state,
        isLoading: false,
        appColor: action.payload.color,
      };
    case actionTypes.UPDATE_COLOR_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.UPDATE_ALERTS_BADGE:
      return {
        ...state,
        alertsAmount: action.payload.quantity,
      };

    case actionTypes.UPDATE_DIGITAL_DOCUMENTS_AMOUNT:
      return {
        ...state,
        digitalDocumentsAmount: action.payload.digitalDocumentsAmount,
      };

    case actionTypes.GET_LOGO:
      return { ...state };
    case actionTypes.GET_LOGO_FULFILLED:
      return {
        ...state,
        logo: action.payload.logo,
      };
    case actionTypes.GET_LOGO_REJECTED:
      return { ...state };

    case actionTypes.EXPORT_ROWS_VIA_SERVER:
      return { ...state, isExportingGrid: true };
    case actionTypes.EXPORT_ROWS_VIA_SERVER_FULFILLED:
      return { ...state, isExportingGrid: false };
    case actionTypes.EXPORT_ROWS_VIA_SERVER_REJECTED:
      return { ...state, isExportingGrid: false };

    case actionTypes.CHANGE_PASSWORD:
      return { ...state, isLoading: true };
    case actionTypes.CHANGE_PASSWORD_FULFILLED:
      return { ...state, isLoading: false };
    case actionTypes.CHANGE_PASSWORD_REJECTED:
      return { ...state, isLoading: false };

    // REQUEST DOCUMENT
    case actionTypes.REQUEST_DOCUMENT:
      return { ...state, isLoadingDocumentsToRequest: true };
    case actionTypes.REQUEST_DOCUMENT_REJECTED:
      return { ...state, isLoadingDocumentsToRequest: false };
    case actionTypes.REQUEST_DOCUMENT_FULFILLED:
      return { ...state, isLoadingDocumentsToRequest: false };

    case actionTypes.GET_DOCUMENTS_LIST:
      return { ...state, isLoadingDocumentsToRequest: true };
    case actionTypes.GET_DOCUMENTS_LIST_REJECTED:
      return { ...state, isLoadingDocumentsToRequest: false };
    case actionTypes.GET_DOCUMENTS_LIST_FULFILLED:
      return {
        ...state,
        docsToRequest: action.payload.docsToRequest,
        isLoadingDocumentsToRequest: false
      };

    case actionTypes.UPDATE_AVATAR:
      return { ...state, avatar: action.payload.avatar };

    case actionTypes.REGISTER_ASSISTANCE:
      return { ...state, isLoadingRegisterAssistance: true };
    case actionTypes.REGISTER_ASSISTANCE_FULFILLED:
      return {
        ...state,
        assistanceData: action.payload.assistance_data,
        isLoadingRegisterAssistance: false,
      };
    case actionTypes.REGISTER_ASSISTANCE_REJECTED:
      return { ...state, isLoadingRegisterAssistance: false };

    case actionTypes.SET_NEW_CONNECTED_USER: {
      const newConnectedUser = action.payload;
      const connectedUsersCopy = { ...state.connectedUsers }; //objeto con los 3 array

      if (newConnectedUser.level && connectedUsersCopy[newConnectedUser.level]) {
        const connectedUserRoleArray = [...connectedUsersCopy[newConnectedUser.level], newConnectedUser]; //agrego al nuevo usuario en array aux del rol del usuario
        connectedUsersCopy[newConnectedUser.level] = connectedUserRoleArray; //piso el array viejo por el aux
      }

      return { ...state, connectedUsers: connectedUsersCopy };
    }
    case actionTypes.SET_NEW_DISCONNECTED_USER: {
      const newDisconnectedUser = action.payload;
      const disconnectedUsersCopy = { ...state.connectedUsers }; //objeto con los 3 array

      const disconnectedUserRoleArray = [...disconnectedUsersCopy[newDisconnectedUser.level]]; //copio el array del rol del user desconectado

      const indexToDelete = disconnectedUserRoleArray.findIndex((user) => user.code === newDisconnectedUser.code); //encontrar indice a borrar
      if (indexToDelete > -1) {
        disconnectedUserRoleArray.splice(indexToDelete, 1); //borrarlo
      }

      disconnectedUsersCopy[newDisconnectedUser.level] = disconnectedUserRoleArray; //pisar el array del rol en el objeto

      return { ...state, connectedUsers: disconnectedUsersCopy };
    }
    default:
      return state;
  }
}
