import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeTheme } from "@icarius-pages/login/actions";
import { getTheme } from "@icarius-pages/login/selectors";
import { setDevExtremeTheme } from "@icarius-utils/theme";
import { setKeyValue } from "@icarius-utils/localStorage";
import { getLogoFromServer } from "src/app/actions";

const useTheme = () => {

  const theme = useSelector(getTheme);
  const dispatch = useDispatch();

  // traer el logo ni bien se entra y cuando cambie el theme
  useEffect(() => {
    dispatch(getLogoFromServer(theme.theme));
  }, [dispatch, theme])

  const handleToggleTheme = () => {
    let data = { theme: "dark" };

    if (theme.theme === "dark") {
      data = { theme: "light" }
      setKeyValue("icarius-theme", "light");
    } else {
      setKeyValue("icarius-theme", "dark");
    }

    document.documentElement.setAttribute('data-theme', data.theme);
    setDevExtremeTheme(data.theme);
    dispatch(changeTheme(data))
  }

  return {
    theme,
    handleToggleTheme,
  };
}

export default useTheme;