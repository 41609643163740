import React from "react";
import { TextField, InputAdornment } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { SearchIcon } from "@icarius-icons/";
import useMenuSearch from "./useMenuSearch";
import { withStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { getAppColor } from "src/app/selectors";

const styles = {
  input: {
    '&::placeholder': {
      opacity: 1,
    },
  },
};

const MenuSearch = ({ routes, handleOpenItem, classes }) => {

  const {
    key,
    options,
    filterOptions,
    onChange,
    getOptionLabel,
    getOptionSelected
  } = useMenuSearch(routes, handleOpenItem)

  const color = useSelector(getAppColor);

  const renderInput = (params) => {
    return (
      <TextField
        {...params}
        placeholder={'Acceso directo'}
        InputProps={{
          classes: {
            input: classes.input,
          },
          disableUnderline: true,
          ref: params.InputProps.ref,
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    )
  }

  return (
    <div className="menuSearchContainer" style={{ borderBottom: `1px solid ${color}` }}>
      <Autocomplete
        fullWidth
        key={key}
        options={options}
        onChange={onChange}
        getOptionSelected={getOptionSelected}
        getOptionLabel={getOptionLabel}
        filterOptions={filterOptions}
        renderInput={renderInput}
        popupIcon={null}
        clearText={'Limpiar'}
        closeText={'Cerrar'}
        noOptionsText={''}
      />
    </div>
  );
}

export default withStyles(styles)(MenuSearch);