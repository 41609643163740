import React, { useState } from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import StyledHelpMenu from "@icarius-common/styledMenu";
import MenuItemWithIcon from "@icarius-common/MenuItemWithIcon";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";

const items = [
  {
    title: getLocalizedString("icariusTech"),
    type: 'video',
    value: "https://www.youtube.com/embed/GeXPlbMMeAs",
  },
  {
    title: getLocalizedString("notes"),
    type: 'video',
    value: "https://www.youtube.com/embed/oY6-PGWBYvQ",
  },
  {
    title: getLocalizedString("pollSliderTitle"),
    type: 'video',
    value: 'https://www.youtube.com/embed/gvm4lL3KZlo',
  },
  {
    title: getLocalizedString("accessByGroups"),
    type: 'video',
    value: 'https://www.youtube.com/embed/AS8LUsd6W9w',
  },
  {
    title: getLocalizedString("tasksAndTelecommuting"),
    type: 'video',
    value: 'https://www.youtube.com/embed/WAu2ZEPvNgk',
  },
  {
    title: getLocalizedString("digitalSignature"),
    type: 'video',
    value: 'https://www.youtube.com/embed/kDrBI7MB0GM',
  },
]

const Help = React.memo(() => {

  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <>
      <CustomIconButton
        noBorder
        type={'help'}
        title={getLocalizedString("help")}
        onClick={(event) => setAnchorEl(event.currentTarget)}
      />
      <StyledHelpMenu
        anchorEl={anchorEl}
        isOpen={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {
          items.map((item, index) => {
            return (
              <MenuItemWithIcon
                key={index}
                value={item.value}
                text={item.title}
                type={item.type}
              />
            )
          })
        }
      </StyledHelpMenu>
    </>
  );
})

export default Help;
